<div class="flex flex-col items-center my-4 py-4 px-8 w-full">
  <div class="max-w-2xl relative w-full desktop-banner" style="background-color: #E5F2FB; padding: 10px; border-radius: 10px">
    <ng-container *ngIf="!isMobile()">
      <div style="float:left;" id="qrCodeContainer"></div>
      <div style="margin-left: 100px;">
        <p style="font-size: 20px;">Download the Plymouth Rock mobile app!</p>
        <p>Scan the QR Code with your phone to get started. The app gives you access to all of your policies, whenever you need it! <a href="#" class="text-es-blue-light">Learn More</a></p>
      </div>
    </ng-container>

    <ng-container *ngIf="isMobile()">
      <div>
        <div style="float:left;">
          <img src="assets/png/new-app-icon.png" width="55"/>
        </div>
        <div style="margin-left: 60px;">
          <p style="font-size: 20px;">Download the Plymouth Rock mobile app!</p>
        </div>
      </div>
      <div>
        <p>The app gives you access to all of your policies, whenever you need it! <a href="#" class="text-es-blue-light">Learn More</a></p>
        <button class="es-btn-primary" (click)="openStore()">Download App</button>
      </div>
    </ng-container>

  </div>
</div>
