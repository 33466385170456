import { environment } from '@prcins/environments';

export const LOGIN_URL = {
    account: `${environment.BASE_URL}/public/api/`,
    authenticate: `${environment.BASE_URL}/public/api/login`,
    securityQuestions: `${environment.BASE_URL}/public/api/forgotpassword/securityquestion`,
    validateQuestion: `${environment.BASE_URL}/public/api/forgotpassword/validatechallenge`,
    resetPassword: `${environment.BASE_URL}/account/changepassword`,
    enrollEdoc:  `${environment.BASE_URL}/public/api/v2/extedocs`,
    AuditUrl: `${environment.BASE_URL}/public/api/audit/electronicconsent`,
    covidRelief: `${environment.BASE_URL}/common/covidrelief`,
    roadRewardsSubsUrl: `${environment.BASE_URL}/common/roadrewards/subscription`
}