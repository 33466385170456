<header class="lg:flex xl:flex p-4 bg-es-blue justify-between items-center border-t-4 border-es-orange">
  <a>
    <img src="assets/svgs/brands/PR/header-logo.svg">
  </a>
</header>

<div class="flex flex-row items-center outer-container" id="desktopContainer">
  <div class="box-container">
    <div class="title">Do more with the App!</div>
    <div class="qr-wrapper">
      <div id="qrCodeContainer" *ngIf="!isMobile()"></div>
      <div *ngIf="!isMobile()"><img src="/assets/png/black-arrow-right.png"></div>
      <div><img src="/assets/png/app-icon.png" width="100"></div>
    </div>

    <div>
      <ul class="app-benefits-ul">
        <li>Easy access to your insurance</li>
        <li>View claims, coverage and discounts</li>
        <li>Better mobile experience</li>
      </ul>
      <div class="store-link-wrapper">
        <button class="es-btn-primary" (click)="openStore()" *ngIf="isMobile()">Download the App</button>
        <button class="es-btn-secondary" (click)="continueOnWeb()">Continue with web browser</button>
      </div>
    </div>

  </div>
</div>

<prcins-footer></prcins-footer>
