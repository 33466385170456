import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LandingComponent } from './landing/landing.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminDeleteAccountComponent } from './admin-delete-account/admin-delete-account.component';
import { ContactEmailComponent } from './contact-email/contact-email.component';
import { LinkPolicyComponent } from './link-policy/link-policy.component';
import { AuthorizeGuard } from './guards/authorize.guard';
import { BalanceDetailsComponent } from './balance-details/balance-details.component';
import { MobileRedirectComponent } from './mobile-redirect/mobile-redirect.component';
import { ManageAlertPreferencesComponent } from 'libs/account/src/lib/manage-alerts-preferences/manage-alert-preferences.component';
import { AdditionalDriversInfoComponent } from '../app/additional-drivers-info/additional-drivers-info.component';
import { AdditionalDriversDetailsComponent } from '../app/additional-drivers-details/additional-drivers-details.component';
import { AdditionalDriversRspnComponent} from '../app/additional-drivers-rspn/additional-drivers-rspn.component';
import { QuestionnaireRenewalDriverErrorComponent } from './questionnaire-renewal-driver-error/questionnaire-renewal-driver-error.component';
import { MobileAppLandingComponent } from "./mobile-app-landing/mobile-app-landing.component";
import { JpmMaintenanceComponent } from './jpm-maintenance/jpm-maintenance.component';


const routes: Route[] = [
  {
    path: 'eservice',
    data: { 'tealium': { 'subSection': 'eService' } },
    children: [
      {
        path: '',
        loadChildren: () => import('@prcins/login').then(m => m.LoginModule)
      },
      {
        path: 'admin/activity',
        component: AdminHomeComponent,
        canActivate: [AuthorizeGuard],
        data: { 'tealium': 'admin' }
      },
      {
        path: 'admin/activity/delete',
        canActivate: [AuthorizeGuard],
        component: AdminDeleteAccountComponent
      },
      {
        path: 'mobile/redirect',
        component: MobileRedirectComponent
      },
      {
        path: 'alerts/preferences/:userId',
        component: ManageAlertPreferencesComponent
      },
      {
        path: 'home',
        canActivate: [AuthorizeGuard],
        canActivateChild: [AuthorizeGuard],
        component: HomeComponent,
        children: [
          {
            path: '',
            component: LandingComponent,
            data: { 'tealium': { 'subSection': 'Home' } }
          },
          {
            path: 'billing',
            loadChildren: () =>
              import('@prcins/billing').then(m => m.BillingModule),
            data: { 'tealium': { 'subSection': 'Payments' } }
          },
          {
            path: 'policy',
            loadChildren: () =>
              import('@prcins/policy').then(m => m.PolicyModule),
            data: { 'tealium': { 'subSection': 'Payments' } }
          },
          {
            path: 'documents',
            loadChildren: () =>
              import('@prcins/documents').then(m => m.DocumentsModule),
            data: { 'tealium': { 'subSection': 'Documents' } }
          },
          {
            path: 'address',
            loadChildren: () =>
              import('@prcins/address').then(m => m.AddressModule),
            data: { 'tealium': { 'subSection': 'Policy' } }
          },
          {
            path: 'claims',
            loadChildren: () =>
              import('@prcins/claims').then(m => m.ClaimsModule),
            data: { 'tealium': { 'subSection': 'Claims' } }
          },
          {
            path: 'payment',
            loadChildren: () =>
              import('@prcins/payment').then(m => m.PaymentModule),
            data: { 'tealium': { 'subSection': 'Payments:Make a Payment' } }
          },
          {
            path: 'account',
            loadChildren: () =>
              import('@prcins/account').then(m => m.AccountModule),
            data: { 'tealium': { 'subSection': 'Account' } }
          },
          {
            path: 'contact',
            children: [
              {
                path: 'email',
                component: ContactEmailComponent
              }
            ]
          },
          {
            path: 'jpmmaintenance',
            component: JpmMaintenanceComponent,
          },
          {
            path: 'balance',
            children: [
              {
                path: 'details',
                component: BalanceDetailsComponent
              }
            ]
          },
          {
            path: 'link-a-policy',
            component: LinkPolicyComponent,
            data: { 'tealium': { 'subSection': 'Link a Policy' } }
          },
          {
            path: 'edit-coverages',
            loadChildren: () =>
              import('@prcins/edit-coverages').then(m => m.EditCoveragesModule),
            data: { 'tealium': { 'subSection': 'Policy' } }
          },
          {
            path: 'add-driver',
            loadChildren: () =>
              import('@prcins/add-driver').then(m => m.AddDriverModule),

          },
          {
            path: 'edit-driver',
            loadChildren: () =>
              import('@prcins/add-driver').then(m => m.AddDriverModule),

          },
          {
            path: 'add-mortgage',
            loadChildren: () =>
              import('@prcins/mortgage').then(m => m.MortgageModule),
          },
          {
            path: 'edit-mortgage',
            loadChildren: () =>
              import('@prcins/mortgage').then(m => m.MortgageModule),
          },
          {
            path: 'replace-mortgage',
            loadChildren: () =>
              import('@prcins/mortgage').then(m => m.MortgageModule),
          },
          {
            path: 'endorsement',
            loadChildren: () =>
              import('@prcins/endorsement').then(m => m.EndorsementModule),
            data: { 'tealium': { 'subSection': 'Policy' } }
          },
          {
            path: 'auto-endorsement',
            loadChildren: () =>
              import('@prcins/features/personal-auto').then(m => m.FeaturesPersonalAutoModule),
          }
        ]
      },
      {
        path:'app', component: MobileAppLandingComponent
      }
    ]
  },
  {
    path: 'qpay',
    loadChildren: () =>
      import('@prcins/quickpay').then(m => m.QuickpayModule),
    data: { 'tealium': { 'subSection': 'Payments' } }
  },
  { path: 'eway/route/local/ui', redirectTo: 'eservice', pathMatch: 'full' },
  {
    path: 'questionnaire/renewal',
    children:[{
        path: 'driver',
        component: AdditionalDriversInfoComponent
      },
      {
        path: 'driver/review',
        canActivate: [AuthorizeGuard],
        component: AdditionalDriversDetailsComponent
      },
      {
        path: 'driver/confirmation',
        canActivate: [AuthorizeGuard],
        component: AdditionalDriversRspnComponent
      },
      {
        path: 'driver/error',
        component: QuestionnaireRenewalDriverErrorComponent
      }]
  },
  {
    path: '', redirectTo: 'eservice', pathMatch: 'full'
  },
  {
    path: '**', redirectTo: 'eservice', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class EserviceRoutingModule { }
